
export default {
  "language": "Español",
  "app": {
    "title": "Atlas",
  },
  "voting": {
    "introPage": {
      "introTitle": "Encuentre su lugar de votación en Atlas",
      "p1_b": "Información sobre la COVID-19: ",
      "p1": "muchos lugares de votación para la Mayo 2021 Primaria Muncipal cambiaron. Consulte aquí para confirmar la ubicación y obtener más información antes de acudir a las urnas el día de la elección.",
      "p2": "Consulte aquí para confirmar la ubicación y obtener más información antes de acudir a las urnas el día de la elección.",
      "p3": "Busque su dirección en el mapa para averiguar lo siguiente:",
      "ul1": {
        "li1": "La dirección y la ubicación en el mapa de su lugar de votación.",
        "li2": "La Información sobre estacionamiento y accesibilidad al edificio.",
        "li3": "Una vista previa de las boletas de la próxima elección.",
        "li4": "Sus funcionarios electos y su información de contacto.",
      },
      "p4_b": "Horario: ",
      "p4": "Todos los lugares de votación estarán abiertos el día de la elección de 7 a.m. a 8 p.m.",
      "p5": "Es posible que los cambios en el registro oficial de votantes no aparezcan aquí inmediatamente. Para actualizar la ubicación de los lugares de votación oficiales en el condado de Filadelfia, comuníquese con la Oficina de Registro de Votantes de Filadelfia al (215) 686-1590.",
      "relatedContent": "Contenido relacionado",
      "link2": "Averigue dónde conseguir y entregar las boletas de voto por correo en persona.",
      "link3": "Consulte el estado de su boleta de voto por correo.",
      "link4": "Confirme que está registrado para votar.",
    },
    "topic": {
      "exclamationCallout1": {
        "p1_b": "Información sobre la COVID-19: ",
        "p1": "muchos lugares de votación para la Mayo 2021 Primaria Muncipal cambiaron. Consulte aquí para confirmar la ubicación y obtener más información antes de acudir a las urnas el día de la elección.",
        "p2_b": "Boletas de voto por correo: ",
        "p2_1": "Las solicitudes de boletas de voto por correo se recibirán en la oficina electoral de su condado hasta el martes 27 de octubre. Encuentre lugares dónde puede",
        "p2_a": "conseguir y entregar las boletas de voto por correo",
        "p2_2": "en persona.",
        "p4": "Solicitar una boleta de voto por correo.",
        "p5": "Si planea ir a votar en persona, confirme su lugar de votación y haga los arreglos necesarios para la votación antes de la elección.",
      },
      "badge1": {
        "specialElection": "Próxima Elección Elegible: Elección Especial",
        "primaryElection": "Próxima Elección Elegible: Elección Primaria",
        "generalElection": "Próxima Elección Elegible: Elección General",
        "header": "La próxima elección es el",
        "content": "3 de noviembre de 2020",
      },
      "hours": "Horario",
      "previewBallot": "Vista previa de la boleta ",
      "electedRep": "Representantes elegidos",
      "cityCouncilRedistricting": "Cambio de Distrito en el Municipio",
      "redistrictingSubtitle": "Algunas direcciones estarán representadas por un nuevo distrito municipal a partir de 2024. Los residentes votarán en el nuevo distrito en las elecciones primarias y generales de 2023.",
      "oldCityCouncilDistrict": "Distrito Anterior del Municipio",
      "newCityCouncilDistrict": "Distrito Nuevo del Municipio",
      "redistrictingProcess": "Más información sobre el proceso de cambio de distritos ",
      "location": "Ubicación",
      "accessibility": "Accesibilidad",
      "parking": "Estacionamiento",
      "districtCouncilMember": "Miembro del consejo de distrito",
      "cityHallOffice": "Oficina del ayuntamiento",
      "currentTerm": "Mandato actual",
      "term": "Mandato",
      "accessibilityCodes": {
        "buildingFullyAccessible": "Edificio totalmente accesible",
        "buildingSubstantiallyAccessible": "Edificio considerablemente accesible",
        "buildingAccessibilityModified": "Edificio con accesibilidad modificada",
        "alternateEntrance": "Entrada alternativa",
        "buildingAccessibleWithRamp": "Edificio accesible con rampa",
        "buildingNotAccessible": "Edificio no accesible",
        "informationNotAvailable": "Información no disponible",
      },
      "parkingCodes": {
        "noParking": "Sin estacionamiento",
        "generalParking": "Estacionamiento general",
        "loadingZone": "Zona de carga",
      },
      "callout1": {
        "text": "La fecha límite para registrarse para la próxima elección es 15 días antes de la elección. Puede confirmar su registro y obtener información sobre cómo registrarse para votar en <a target='_blank' href='https://www.philadelphiavotes.com/'>www.philadelphiavotes.com</a>.",
      },
      "pollingPlace": "Lugar de votación",
      "verticalTable1": {
        "link": "Obtenga información sobre sus opciones para votar si no puede ir a votar en persona el día de la elección ",
      },
      "verticalTable2": {
        "link": "Vea todos los representantes federales, estatales y de la ciudad ",
      },
    },
  },
};
