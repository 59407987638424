
export default {
  "language": "English",
  "app": {
    "title": "Atlas",
  },
  "voting": {
    "introPage": {
      "introTitle": "Find your polling place on Atlas",
      "p1_b": "COVID-19 info: ",
      "p1": "Many polling places for the May 2021 Municipal Primary have changed. Check here to confirm the location and more before you head to the polls on election day.",
      "p2": "Check here to confirm the location and more before you head to the polls on election day.",
      "p3": "Search your home address on the map to find:",
      "ul1": {
        "li1": "Your polling place address and map location.",
        "li2": "Accessibility and parking information for the building.",
        "li3": "A preview of the upcoming election ballot.",
        "li4": "Your elected officials and their contact information.",
      },
      "p4_b": "Hours: ",
      "p4": "All polling places will be open on election day from 7 a.m. to 8 p.m.",
      "p5": "Changes to the official voter registry may not be reflected here immediately. For up-to-date, official polling place locations in Philadelphia County, contact the Philadelphia Voter Registration Office at (215) 686-1590.",
      "relatedContent": "Related content",
      "link2": "Find where to get and return mail-in ballots in person",
      "link3": "Check the status of your mail-in ballot",
      "link4": "Confirm that you're registered to vote",
    },
    "topic": {
      "exclamationCallout1": {
        "p1_b": "COVID-19 info: ",
        "p1": "Many polling places for the May 2021 Municipal Primary have changed. Check here to confirm the location and more before you head to the polls on election day.",
        "p2_b": "Mail in ballots:",
        "p2_1": "Applications must be received by your county election office no later than Tuesday, October 27th. Find places you can",
        "p2_a": "get and return mail-in ballots",
        "p2_2": "in person.",
        "p4": "Request a mail-in ballot.",
        "p5": "If you are planning on voting in person, please confirm your polling place and make voting arrangements prior to the election.",
      },
      "badge1": {
        "specialElection": "Next Eligible Election: Special Election",
        "primaryElection": "Next Eligible Election: Primary Election",
        "generalElection": "Next Eligible Election: General Election",
        "header": "Next Eligible Election Is",
        "content": "November 3, 2020",
      },
      "hours": "Hours",
      "previewBallot": "Preview ballot ",
      "electedRep": "Elected Representatives",
      "cityCouncilRedistricting": "City Council Redistricting",
      "redistrictingSubtitle": "Some addresses will be represented by a new city council district starting in 2024. Residents will vote in the new district in the 2023 primary and general elections.",
      "oldCityCouncilDistrict": "Old City Council District",
      "newCityCouncilDistrict": "New City Council District",
      "redistrictingProcess": "Read more about the redistricting process ",
      "location": "Location",
      "accessibility": "Accessibility",
      "parking": "Parking",
      "districtCouncilMember": "District Council Member",
      "cityHallOffice": "City Hall Office",
      "currentTerm": "Current Term",
      "term": "Term",
      "accessibilityCodes": {
        "buildingFullyAccessible": "Building Fully Accessible",
        "buildingSubstantiallyAccessible": "Building Substantially Accessible",
        "buildingAccessibilityModified": "Building Accessibility Modified",
        "alternateEntrance": "Alternate Entrance",
        "buildingAccessibleWithRamp": "Building Accessible With Ramp",
        "buildingNotAccessible": "Building Not Accessible",
        "informationNotAvailable": "Information not available",
      },
      "parkingCodes": {
        "noParking": "No Parking",
        "generalParking": "General Parking",
        "loadingZone": "Loading Zone",
      },
      "callout1": {
        "text": "The deadline to register for the next election is 15 days prior to the election. You can confirm your registration and learn about registering to vote at <a target='_blank' href='https://vote.phila.gov/'>vote.phila.gov</a>.",
      },
      "pollingPlace": "Polling Place",
      "verticalTable1": {
        "link": "Learn about your voting options if you cannot vote in person on Election Day ",
      },
      "verticalTable2": {
        "link": "See all citywide, state, and federal representatives ",
      },
    },
  },
};
